<template>
  <div>
    <h6 class="text-h6 mb-5">{{ $t('common.details') }}</h6>
    <v-divider class="mb-8"></v-divider>
    <v-row dense>
      <v-col cols="12" md="6">
        <base-field-input
          v-model="item.attributes.name"
          :placeholder="`${$tc('facility.name', 1)} 1`"
          :label="$t('common.name')"
      /></v-col>
      <v-col cols="12" md="6"
        ><base-selector
          v-model="item.attributes.category"
          :items="
            facilityCategories.map(f => ({
              text: $tc(`facility.${f.value}`, 1),
              value: f.value
            }))
          "
          :label="$tc('common.category', 1)"
      /></v-col>
    </v-row>

    <h6 class="text-h6 mb-5">{{ $t('common.contactPerson') }}</h6>
    <v-divider class="mb-8"></v-divider>
    <v-row dense
      ><v-col cols="12">
        <base-field-input
          v-model="item.attributes.contactPerson"
          :label="$t('common.name')"/></v-col
      ><v-col cols="12" md="6">
        <base-field-input
          v-model="item.attributes.email"
          type="email"
          :label="$t('common.email')"/></v-col
      ><v-col cols="12" md="6">
        <base-field-input
          v-model="item.attributes.phone"
          :label="$t('common.phone')"/></v-col
    ></v-row>

    <h6 class="text-h6 mb-5">{{ $t('common.address') }}</h6>
    <v-divider class="mb-8"></v-divider>
    <base-textarea-input
      v-model="item.attributes.address"
      placeholder="North Warehouse..."
      :label="$t('common.address')"
    />
    <h6 class="text-h6 mb-5">{{ $tc('document.attachment', 2) }}</h6>
    <v-divider class="mb-8"></v-divider>
    <base-file-input />
  </div>
</template>

<script>
import { FACILITY_CATEGORIES } from '@/common/terms'
export default {
  name: 'FacilityEditForm',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      facilityCategories: FACILITY_CATEGORIES
    }
  }
}
</script>
