<template>
  <base-form-layout
    :title="`${action} ${$tc('facility.name', 1)}`"
    @submit="onSubmit"
  >
    <v-form ref="form">
      <FacilityEditForm :item="facility" />
    </v-form>
  </base-form-layout>
</template>

<script>
import FacilityEditForm from './components/FacilityEditForm'
import { FACILITY } from '@/models/facility'
import { FormMixin } from '@/mixins/form.mixin'
import { DocumentMixin } from '@/mixins/document.mixin.js'
import { mapActions } from 'vuex'
export default {
  components: {
    FacilityEditForm
  },
  mixins: [FormMixin, DocumentMixin],
  props: {
    facilityId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      facility: FACILITY(),
      isLoading: false
    }
  },
  computed: {
    action() {
      return this.$t(this.facilityId ? 'actions.edit' : 'actions.create')
    }
  },
  async created() {
    if (this.facilityId) this.setup()
  },
  methods: {
    ...mapActions({
      addNotification: 'notification/add',
      fetchFacility: 'facility/fetch'
    }),

    async setup() {
      this.isLoading = true
      const { data } = await this.fetchFacility(this.facilityId)
      this.facility = data
      this.isLoading = false
    },
    async onSubmit() {
      this.beforeSubmit = false
      try {
        const action = this.facilityId ? 'update' : 'create'
        const response = await this.$store.dispatch(
          `facility/${action}`,
          this.facility
        )
        await this.createDocument(response.id, 'Facility', 'Attachment')
        this.addNotification({
          status: '200',
          color: 'success',
          time: 2000,
          messages: { title: `Successfully ${action}d` }
        })
        this.isLoading = false
        this.$router.go(-1)
      } catch (error) {
        this.isLoading = false
        if (!error.response) return
        const {
          status,
          data: { errors }
        } = error.response
        return this.addNotification({
          status,
          messages: errors,
          color: 'error',
          time: 10000
        })
      }
    }
  }
}
</script>
