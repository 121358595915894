import { ORGANIZATION_ID } from '@/common/config.js'

export const FACILITY = () => ({
  type: 'facility',
  attributes: {
    organizationId: ORGANIZATION_ID(),
    name: '',
    category: 'warehouse',
    contactPerson: '',
    address: '',
    phone: '',
    email: '',
    customAttributes: {}
  }
})
